<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="LAW_ID"
            itemText="codeName"
            itemValue="code"
            name="lawId"
            label="법령"
            v-model="searchParam.lawId"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="법규등록부 목록"
      tableId="lawregisterpop"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :selection="popupParam.type"
      rowKey="saiLawRegisterMasterItemId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-register-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        lawId: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'lawId' },
          { index: 1, colName: 'saiLawRegisterMasterId' },
        ],
        columns: [
          {
            name: 'lawName',
            field: 'lawName',
            label: '법령명',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'gubunName',
            field: 'gubunName',
            label: '구분',
            align: 'left',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '항목',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'relevantClause',
            field: 'relevantClause',
            label: '관련조항',
            align: 'left',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'requirements',
            field: 'requirements',
            label: '요구사항',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'applyConstruction',
            field: 'applyConstruction',
            label: '적용사항',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
          {
            name: 'target',
            field: 'target',
            label: '적용대상',
            align: 'left',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'relatedDocument',
            field: 'relatedDocument',
            label: '관련문서(기록)',
            align: 'left',
            style: 'width:80px',
            sortable: false,
          },
        ],
        data: [],
        height: '700px'
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.lawRegister.item.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 정보가 없습니다.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
